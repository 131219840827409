import React from 'react';
import { Layout, Link } from '../components';
import bully from '../images/tilted_bully.svg';
import './style.scss';

const NotFoundPage = () => (
  <Layout subtitle="Page not Found">
    <div style={{ height: '75vh', minHeight: 550 }}>
      <div className="hero-text" style={{ color: 'rgba(0, 0, 0, 0.8)' }}>
        <img
          src={bully}
          alt="Lost Bully"
          style={{ margin: '50px auto', height: '25vh' }}
        />
        <h1>Jeepers Mister (or Miss)!</h1>
        <h4>The page you're looking for doesn't exist.</h4>
        <p>Sorry about that. :\</p>
        <p>
          <Link to="/">Click here</Link> to go to the home page.
        </p>
        <p>
          You can also use the navigation bar above, or the sitemap below to get
          around.
        </p>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
